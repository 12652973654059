<template>
    <informations-base>
        <template slot="informations-content">

            <div>
                <update-visual update_visual_key="cover"
                               :width="800" :height="400"
                               :crop_width="1180" :crop_height="300"
                               :aspect_ratio="11 / 2"
                               text_position="bottom-4 right-4"
                               image_class="border border-slate-200"
                               portal="profile-visual">
                    {{ $t('visibility.informations.cover.edit') }}
                </update-visual>

                <div class="relative">
                    <div class="absolute pl-2 -top-10 shad">
                        <div class="w-[178px] shadow-md">
                            <update-visual update_visual_key="logo"
                                           :width="450" :height="450"
                                           text_position="bottom-0.5 left-0.5 right-0.5"
                                           image_class="border border-slate-200 aspect-square w-full bg-white"
                                           portal="profile-visual2">
                                {{ $t('visibility.informations.logo.edit') }}
                            </update-visual>
                        </div>
                    </div>

                    <div class="flex flex-col pt-4 pl-12 ml-44">
                        <div class="flex items-center justify-between">
                            <h2 class="text-2xl font-bold xl:text-3xl" v-html="$store.state.professional.company"></h2>
                            <div class="flex space-x-2">
                                <v-spa-loading-button :color="$spa.css.button.color.white" extraClass="border border-slate-200" @clicked="$modal.show('information-update-modal')">
                                    <i class="fas fa-pencil"></i> {{ $t('table.actions.edit') }}
                                </v-spa-loading-button>
                                <v-spa-link-button :to="''" :href="$store.state.professional.profile_url" target="_blank" rel="noopener">
                                    {{ $t('visibility.informations.public_page_button') }}
                                </v-spa-link-button>
                            </div>
                        </div>

                        <div class="font-medium text-gray-800 cursor-pointer w-fit" @click="$modal.show('information-update-modal')" v-html="$store.state.professional.address.display"></div>
                    </div>
                </div>

                <div class="mb-8 mt-28">
                    <h3 class="mb-2 font-bold text-slate-800">{{ $t('visibility.informations.presentation') }}</h3>
                    <p class="w-2/3 text-sm text-gray-700 revert-list" v-html="$store.state.professional.presentation"></p>
                </div>

                <div class="flex space-x-2">
                    <div class="px-4 py-2 text-sm cursor-pointer text-slate-800 rounded-xl bg-neutral-200 hover:bg-neutral-400" @click="$modal.show('information-update-modal')" v-if="$store.state.professional.email" v-html="$store.state.professional.email"></div>
                    <div class="px-4 py-2 text-sm cursor-pointer text-slate-800 rounded-xl bg-neutral-200 hover:bg-neutral-400" @click="$modal.show('information-update-modal')" v-if="$store.state.professional.phone" v-html="$store.state.professional.phone"></div>
                    <div class="px-4 py-2 text-sm cursor-pointer text-slate-800 rounded-xl bg-neutral-200 hover:bg-neutral-400" @click="$modal.show('information-update-modal')" v-if="$store.state.professional.website" v-html="$store.state.professional.website"></div>
                </div>
            </div>

            <portal to="modals">
                <v-spa-right-panel-modal name="information-update-modal" rightPanelWidth="550px" rightPanelWidthXl="550px">
                    <form @submit.prevent="update">
                        <div class="w-full border-b border-grey-lighter">
                            <v-spa-address-input id="form_address" :form="form" input-name="address"></v-spa-address-input>
                            <v-spa-input id="form_email" :form="form" input-name="email"></v-spa-input>
                            <v-spa-phone-input id="form_phone" :form="form" input-name="phone"></v-spa-phone-input>
                            <v-spa-input id="form_website" :form="form" input-name="website"></v-spa-input>

                            <div class="text-gray-500 font-normal block mb-1 cursor-pointer text-sm">{{ $t('form.labels.presentation') }}</div>
                            <vue-editor id="form_presentation" class="mb-6" :editorToolbar="customToolbar" v-model="form.presentation"></vue-editor>
                            <v-spa-textarea id="form_search_presentation" :form="form" input-name="search_presentation"></v-spa-textarea>

                            <v-spa-element id="form_locales" :form="form" input-name="locales">
                                <a href="#" @click.prevent="toggleLocale(locale.locale)" class="flex items-center w-full py-2 text-sm text-gray-800 group" v-for="(locale, index) in locales" :key="locale.locale">
                                <span class="flex items-center justify-center w-4 h-4 mr-2 border-2 border-gray-200 rounded-full flex-no-shrink group-hover:border-primary" :class="{ 'border-primary': form.locales.includes(locale.locale), 'border-gray-200': ! form.locales.includes(locale.locale) }">
                                    <template v-if="form.locales.includes(locale.locale)">
                                        <span class="w-2 h-2 rounded-full bg-primary"></span>
                                    </template>
                                </span>
                                    <span class="flex-1">{{ locale.native }}</span>
                                </a>
                            </v-spa-element>
                        </div>
                        <div class="flex flex-row-reverse mt-6">
                            <v-spa-loading-button :loading="form.is_loading">{{ $t('form.buttons.update') }}</v-spa-loading-button>
                        </div>
                    </form>
                </v-spa-right-panel-modal>
            </portal>

        </template>
    </informations-base>
</template>

<script>
    import Form from '~spa/classes/Form';
    import Base from './Base';
    import UpdateVisual from "~base/components/_Layout/TmpSpa/UpdateVisual";
    import { VueEditor } from 'vue2-quill-editor'

    export default {
        components: {
            'informations-base': Base,
            UpdateVisual,
            VueEditor
        },

        data() {
            return {
                locales: [],
                loading: true,
                form: new Form({
                    address: null,
                    phone: '',
                    phone_country: 'BE',
                    email: '',
                    website: '',
                    presentation: '',
                    search_presentation: '',
                    locales: []
                }),

                editCover: false,
                editLogo: false,
                customToolbar: [
                    ['bold', 'italic'],
                    ['link'],          
                    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                ]
            }
        },

        methods: {
            toggleLocale(locale)
            {
                let index = this.form.locales.indexOf(locale);
                if ( index !== -1 ) {
                    this.form.locales.splice(index, 1);
                    return;
                }

                this.form.locales.push(locale);
            },

            async update() {
                if ( this.form.website !== '' ) {
                    this.form.website = this.addHttp(this.form.website);
                }

                let {professional} = await this.form.put(`${window.api_url}/me/professionals/${this.$store.state.professional.id}`)

                window.Toasteo.success( this.$t('visibility.informations.edit.success') );
                this.$store.commit('setProfessional', professional);
                this.$modal.hide('information-update-modal')

            },

            updateCover()
            {
                this.updateVisual('cover', this.$refs.cropCover.getCroppedResult());
            },

            updateLogo()
            {
                this.updateVisual('logo', this.$refs.cropLogo.getCroppedResult());
            },
        },

        created() {
            this.form.fill(this.$store.state.professional);
            this.$store.dispatch('getLocales')
                .then((locales) => {
                    this.locales = locales;
                    this.loading = false;
                });
        }
    }
</script>